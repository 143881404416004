(function ($) {

    "use strict";

    $(document).ready(function () {
        $(window).load(function () {
            $('.main-slider').flexslider({
                animation: "fade",
                directionNav: false,
                slideshowSpeed: 5000,
                animationSpeed: 600,
                initDelay: 0,
                useCSS: true,
                touch: true,
                video: true,
                start: function (slider) { //to handle first slide when slider first loads
                    var currSlide = slider.slides.eq(slider.currentSlide);
                    currSlide.find('.flex-caption').addClass("animate-in");

                },
                before: function (slider) { // handles rest of slides
                    var nextSlide = slider.slides.eq(slider.animatingTo);
                    var currSlide = slider.slides.eq(slider.currentSlide);
                    currSlide.find('.flex-caption').addClass("animate-out").removeClass("animate-in");
                    nextSlide.find('.flex-caption').addClass("animate-in").removeClass("animate-out");

                }
            });
        });
    });

})(jQuery);